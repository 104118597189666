/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        function relojFecha(){

            var mydate=new Date();var year=mydate.getYear();
            if (year < 1000)year+=1900;

            var day=mydate.getDay();

            var month=mydate.getMonth();

            var daym=mydate.getDate();

            if (daym<10)daym="0"+daym;

            var montharray=new Array("1","2","3","4","5","6","7","8","9","10","11","12");

            var horas = mydate.getHours();

            horas = (horas<10)?"0"+horas:horas;

            var minutos = mydate.getMinutes();

            minutos = (minutos<10)?"0"+minutos:minutos;

            var segundos = mydate.getSeconds();

            segundos = (segundos<10)?"0"+segundos:segundos;

            var mt = "AM";
        	// Pongo el formato 12 horas
        	if (horas> 12) {
        	mt = "PM";
        	horas = horas - 12;
        	}


          cadenaUrl = window.location.pathname;
          var n = cadenaUrl.indexOf("/en/");

          if (n != "") {
            document.getElementById("idReloj").innerHTML = "<"+"small><"+"font color='#555' face='Verdana'>Fecha:"+daym+"/"+montharray[month]+"/"+year+"  &nbsp;  Hora: "+horas+":"+minutos+":"+segundos+""+mt+"<"+"/font><"+"/small>";
          }else {
            document.getElementById("idReloj").innerHTML = "<"+"small><"+"font color='#555' face='Verdana'>Date:"+daym+"/"+montharray[month]+"/"+year+"  &nbsp;  Hour: "+horas+":"+minutos+":"+segundos+""+mt+"<"+"/font><"+"/small>";

          }


            setTimeout(function(){ relojFecha(); }, 1000);

        }
        relojFecha();

        var url      = window.location.pathname;
        if (url == "/hwelle/productos/" || url == "/productos/" ) {
          console.log(url);
          $("a[href='https://hwelle.com/productos/']").parent('li').addClass('current_page_item');
        }else if (url == "/hwelle/blog/" || url == "/blog/" ) {
          $("a[href='https://hwelle.com/blog/']").parent('li').addClass('current_page_item');
        }

        var swiper = new Swiper('.swiper-container', {
            pagination: '.swiper-pagination',
            paginationClickable: true
        });

        /*formulario home*/
        $('.content-form').find('input, textarea, button').attr('disabled','disabled');
        $('select.sucursal').change(function(event){
           event.preventDefault()
           $('.content-form').find('input, textarea, button').removeAttr('disabled');
           $('.form-home-cont').hide('fast');
           var suc = '.'+$('select.sucursal').val()
           $(suc).show('slow');
        });



        $('.itens-opc').on('click', function(event){
           event.preventDefault()
          $(".itens-opc").removeClass('active-iten');
          $(this).addClass('active-iten');
           valor = $(this).attr("data-iten");
          $(".itens-product").removeClass('active-iten');
          $('.'+valor).addClass('active-iten');
        });


        $('select.cont-itens-select').change(function(event){
           event.preventDefault()
           valor = $(this).val();
          $(".itens-product").removeClass('active-iten');
          $('.'+valor).addClass('active-iten');

        });



        $('.menu-values.about a.btn-hwelle').on('click', function(event){
           event.preventDefault()
          $(".menu-values .btn-hwelle").removeClass('active');
          $(this).addClass('active');
          valor = $(this).html();
          $(".desc-values-us").removeClass('active');
          $('.'+valor).addClass('active');

        });

        $('select.bonus').change(function(event){
           event.preventDefault()
          valor = $(this).val();
          console.log(valor);
          $(".desc-values-us").removeClass('active');
          $('.'+valor).addClass('active');

        });

        $('.menu-values.bonus a.btn-hwelle').on('click', function(event){
           event.preventDefault()
          $(".menu-values.bonus .btn-hwelle").removeClass('active');
          $(this).addClass('active');
          valor = $(this).attr("data-bonus");
          $(".desc-values-us").removeClass('active');
          $('.'+valor).addClass('active');

        });


      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'page': {
      init: function() {
        // JavaScript to be fired on the home page

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    'single': {
      init: function() {

        var itens = $(".cont-itens li" ).length;
        if(itens == 1){
          $('ul.cont-itens').addClass('one');
        }else if (itens == 2) {
          $('ul.cont-itens').addClass('two');
        }else if (itens == 3) {
          $('ul.cont-itens').addClass('three');
        }else if (itens == 4) {
          $('ul.cont-itens').addClass('four');

        }

        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
